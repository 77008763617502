$(document).ready(function() {
    $('#how-it-will-work-carousel').slick({
        slidesToShow: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 7000,
        fade:true
    });

    $('#how-it-will-work-carousel').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        var CurrentSlideDom = $(slick.$slides.get(currentSlide));
        var currentSlideProperties = CurrentSlideDom.children('div').children('div').attr('data-name');

        document.querySelectorAll('.how-it-will-work-item').forEach(function (item) {
            var text = $(item).html();;
            item.classList.remove('list-active-name');
            if (text.trim() === currentSlideProperties) {

                item.classList.add('list-active-name');
            }
        });
    });

    $('.how-it-will-work-item').on('click', function () {
        var text = $(this).html().trim();
        var carousel = $('#how-it-will-work-carousel').slick("getSlick");
        var slideProperties = carousel.$slides.children();
        slideProperties.each(function (id, item){
            var something = item.childNodes[0].getAttribute('data-name');
            if(something === text){
                $('#how-it-will-work-carousel').slick('slickGoTo', id);
            }
        });
    });

    $('#how-it-will-look-carousel').slick({
        slidesToShow: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 7000,
        fade:true
    });

    $('#how-it-will-look-carousel').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        var CurrentSlideDom = $(slick.$slides.get(currentSlide));
        var currentSlideProperties = CurrentSlideDom.children('div').children('div').attr('data-name');

        document.querySelectorAll('.how-it-will-look-item').forEach(function (item) {
            var text = $(item).html();
            item.classList.remove('list-active-name');
            if (text.trim() === currentSlideProperties) {
                item.classList.add('list-active-name');
            }
        });
    });

    $('.how-it-will-look-item').on('click', function () {
        var text = $(this).html().trim();
        var carousel = $('#how-it-will-look-carousel').slick("getSlick");
        var slideProperties = carousel.$slides.children();
        slideProperties.each(function (id, item){
            var something = item.childNodes[0].getAttribute('data-name');
            if(something === text){
                $('#how-it-will-look-carousel').slick('slickGoTo', id);
            }
        });
    });

    $('#e-commerce-carousel').slick({
        slidesToShow: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 7000,
        fade:true
    });

    $('#e-commerce-carousel').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        var CurrentSlideDom = $(slick.$slides.get(currentSlide));
        var currentSlideProperties = CurrentSlideDom.children('div').children('div').attr('data-name');

        document.querySelectorAll('.e-commerce-item').forEach(function (item) {
            var text = $(item).html();
            item.classList.remove('list-active-name');
            if (text.trim() === currentSlideProperties) {
                item.classList.add('list-active-name');
            }
        });
    });

    $('.e-commerce-item').on('click', function () {
        var text = $(this).html().trim();
        var carousel = $('#e-commerce-carousel').slick("getSlick");
        var slideProperties = carousel.$slides.children();
        slideProperties.each(function (id, item){
            var something = item.childNodes[0].getAttribute('data-name');
            if(something === text){
                $('#e-commerce-carousel').slick('slickGoTo', id);
            }
        });
    });

    $('#whatYouCanDo-carousel').slick({
        slidesToShow: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 7000,
        fade:true
    });

    $('#whatYouCanDo-carousel').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        var CurrentSlideDom = $(slick.$slides.get(currentSlide));
        var currentSlideProperties = CurrentSlideDom.children('div').children('div').attr('data-name');

        document.querySelectorAll('.whatYouCanDo-item').forEach(function (item) {
            var text = $(item).html();
            item.classList.remove('list-active-name');
            if (text.trim() === currentSlideProperties) {
                item.classList.add('list-active-name');
            }
        });
    });

    $('.whatYouCanDo-item').on('click', function () {
        var text = $(this).html().trim();
        var carousel = $('#whatYouCanDo-carousel').slick("getSlick");
        var slideProperties = carousel.$slides.children();
        slideProperties.each(function (id, item){
            var something = item.childNodes[0].getAttribute('data-name');
            if(something === text){
                $('#whatYouCanDo-carousel').slick('slickGoTo', id);
            }
        });
    });

    $('#onlyWithInteractively-carousel').slick({
        slidesToShow: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 7000,
        fade:true
    });

    $('#onlyWithInteractively-carousel').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        var CurrentSlideDom = $(slick.$slides.get(currentSlide));
        var currentSlideProperties = CurrentSlideDom.children('div').children('div').attr('data-name');

        document.querySelectorAll('.onlyWithInteractively-item').forEach(function (item) {
            var text = $(item).html();
            item.classList.remove('list-active-name');
            if (text.trim() === currentSlideProperties) {
                item.classList.add('list-active-name');
            }
        });
    });

    $('.onlyWithInteractively-item').on('click', function () {
        var text = $(this).html().trim();
        var carousel = $('#onlyWithInteractively-carousel').slick("getSlick");
        var slideProperties = carousel.$slides.children();
        slideProperties.each(function (id, item){
            var something = item.childNodes[0].getAttribute('data-name');
            if(something === text){
                $('#onlyWithInteractively-carousel').slick('slickGoTo', id);
            }
        });
    });

    $('#how-it-will-work-carousel-mobile').slick({
        slidesToShow: 1,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 7000,
        fade:true
    });
    $('#how-it-will-look-carousel-mobile').slick({
        slidesToShow: 1,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 7000,
        fade:true
    });
    $('#e-commerce-carousel-mobile').slick({
        slidesToShow: 1,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 7000,
        fade:true
    });
    $('#whatYouCanDo-carousel-mobile').slick({
        slidesToShow: 1,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 7000,
        fade:true
    });
    $('#onlyWithInteractively-carousel-mobile').slick({
        slidesToShow: 1,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 7000,
        fade:true
    });

});
